<template>
	<div>
		<el-drawer
			title="我是标题"
			:visible.sync="drawer"
			:direction="direction"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>新增账号</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
					<el-form-item label="" prop="realName">
						<div class="inputtitle">
							<span>*</span>
							姓名
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.realName" placeholder="请输入姓名"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="" prop="mobile">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							手机号
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="" prop="departmentId">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							所属部门
						</div>
						<el-cascader
						@change="getChoose"
							placeholder="请选择部门"
							ref="myCascader"
							v-model="ruleForm.departmentId"
							:options="options"
							:props="{ checkStrictly: true }"
							clearable
						></el-cascader>
					</el-form-item>
					<el-form-item label="" prop="isLeader">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							职位
						</div>
						<el-select v-model="ruleForm.isLeader" placeholder="请选择职位">
							<el-option key="0" label="普通员工" value="0"></el-option>
							<el-option key="1" label="负责人" value="1"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="" prop="roleId">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							用户角色
						</div>
						<div class="allway">
							<div
								class="oneway"
								v-for="(item, index) in jueseList"
								@click="chooseWay(item.roleId)"
							>
								<div :class="{ activeway: item.roleId === ruleForm.roleId }">
									<p v-if="item.roleId === ruleForm.roleId"></p>
								</div>
								<span>{{ item.roleName }}</span>
							</div>
						</div>
					</el-form-item>
					<!-- <el-form-item label="" prop="loginPassword">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							设置密码
						</div>
						<div class="inputbox">
							<el-input
								type="password"
								v-model="ruleForm.loginPassword"
								placeholder="请输入密码"
							></el-input>
						</div>
					</el-form-item>
					
					<el-form-item label="" prop="confirmPassword">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							确认密码
						</div>
						<div class="inputbox">
							<el-input
								type="password"
								v-model="ruleForm.confirmPassword"
								placeholder="请再次输入密码"
							></el-input>
						</div>
					</el-form-item> -->
					<!-- <el-form-item label="" prop="headPortrait">
						<div class="inputtitle" style="margin-top: 25px;">
							
							账号头像
						</div>
						<el-upload
						name="file"
							class="avatar-uploader"
							:action="$baseUrl + '/common/uploadFile'"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
						>
							<img v-if="ruleForm.headPortrait" :src="ruleForm.headPortrait" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item> -->
				<!-- 	<el-form-item label="" prop="loginName">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							账号名称
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.loginName" placeholder="请输入账号名称"></el-input>
						</div>
					</el-form-item> -->
					
				</el-form>
				<div class="twobtn">
					<div @click="resetForm('ruleForm')">取消</div>
					<div class="save" @click="submitForm('ruleForm')">保存</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import jsSHA from 'jssha';
import { addStaff, roleList, departmentTree } from '@/network/api.js';
export default {
	data() {
		return {
			options: [],

			jueseList: [],
			ruleForm: {
				confirmPassword: '', // 确认密码
				departmentId: [], // 所属部门id
				headPortrait: '', // 头像
				isLeader: '', // 账号类型 0 普通员工 1 负责人
				loginName: '', // 登录账号
				loginPassword: '', // 登录密码(sha-512加密值)
				mobile: '', // 手机号
				realName: '', // 姓名
				roleId: '' ,// 角色id
				level: '', // 部门层级
			},
			rules: {
				confirmPassword: [{ required: true, message: '请输入确认密码', trigger: 'blur' }],
				departmentId: [
					{ type: 'array', required: true, message: '请选择所属部门', trigger: 'blur' }
				],
				isLeader: [{ required: true, message: '选择账号类型', trigger: 'blur' }],
				// loginName: [{ required: true, message: '请输入账号名称', trigger: 'blur' }],
				loginPassword: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
				mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
				// headPortrait: [{ required: true, message: '请选择头像', trigger: 'blur' }],
				realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
				roleId: [{ required: true, message: '请选择角色', trigger: 'blur' }]
			},
			drawer: false,
			direction: 'rtl'
		};
	},
	created() {
		this._roleList();
		this._departmentTree();
	},
	methods: {
		// 获取选中的部门数据
		getChoose(val) {
			// console.log(val);
			this.ruleForm.level = val.length
		},
		handleAvatarSuccess(res, file) {
			// this.imageUrl = ;
			// console.log();
			this.ruleForm.headPortrait = res.data.fileUrl
		},
		beforeAvatarUpload(file) {
			let list = ['image/jpeg', 'image/jpg', 'image/png']
			const isJPG = list.includes(file.type);
			const isLt2M = file.size / 1024 / 1024 < 20;

			if (!isJPG) {
				this.$message.error('上传头像图片只能是 JPG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 20MB!');
			}
			return isJPG && isLt2M;
		},
		//  密码加密--sha512
		getPassword(password) {
			const shaObj = new jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' });
			shaObj.update(password);
			return shaObj.getHash('HEX');
		},
		// 将部门数据转化为可用数据
		fromatInfo(info) {
			let data = info.map(item => {
				let data1 = {
					value: item.departmentId,
					label: item.departmentName,
					parentDepartmentId: item.parentDepartmentId,
					level: item.level,
					children: item.departmentChildren
				};
				if (item.departmentChildren && item.departmentChildren.length > 0) {
					data1.children = this.fromatInfo(item.departmentChildren);
				}
				return data1;
			});
			return data;
		},
		// 获取部门树
		async _departmentTree() {
			const res = await departmentTree();
			// console.log('部门树', res.data);
			this.options = this.fromatInfo(res.data);
			// console.log('部门树', this.options);

			// res.data.forEach()
		},
		// 获取角色列表
		async _roleList() {
			const res = await roleList({
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10000,
				roleName: '',
				searchBody: ''
			});
			// console.log('角色列表', res);
			this.jueseList = res.data.list;
			this.totalCount = res.data.totalCount;
		},
		// 添加账号
		async _addStaff() {
			let info = JSON.parse(JSON.stringify(this.ruleForm));
			let sub = this.ruleForm.mobile.substring(this.ruleForm.mobile.length-6)
			info.departmentId = this.ruleForm.departmentId[0];
			info.confirmPassword = this.getPassword(sub);
			info.loginPassword = this.getPassword(sub);
			console.log('ffffffff',info)
			const res = await addStaff(info);
			this.$myMessage('添加成功', 'success');
			this.drawer = false;
			this.$emit('updataInfo');
			this.resetForm();
		},
		submitForm(formName) {
			console.log(this.ruleForm);
			this.ruleForm.loginName = this.ruleForm.mobile;
			this.$refs[formName].validate(valid => {	
				if (valid) {
					if (this.ruleForm.confirmPassword !== this.ruleForm.loginPassword) {
						return this.$myMessage('两次输入密码不一致，请重新输入', 'error');
					}
					this._addStaff();
					
				} else {
					return false;
				}
			});
		},
		resetForm() {
			this.$refs['ruleForm'].resetFields();
			this.drawer = false;
		},
		// 选择跟进方式
		chooseWay(id) {
			this.ruleForm.roleId = id;
		}
	}
};
</script>

<style scoped lang="scss">
.avatar-uploader {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	width: 120px;
	height: 120px;
}
.el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	width: 120px;
	height: 120px;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 120px;
	height: 120px;
	line-height: 120px;
	text-align: center;
}
.avatar {
	width: 178px;
	height: 178px;
	display: block;
}
::v-deep {
	.el-form-item__content {
		line-height: 20px;
	}
	.el-drawer {
		border-radius: 10px 0px 0px 10px !important;
		width: 700px !important;
	}
}
::v-deep {
	.inputbox {
		.el-input__inner {
			width: 600px !important;
			height: 40px !important;
		}
	}

	.el-form-item {
		margin: 0;
	}
}
::v-deep {
	.choosetime {
		.el-input__inner {
			width: 400px !important;
			height: 40px !important;
		}
	}
}

.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}
.oneway {
	cursor: pointer;
	margin: 0 30px 10px 0;
	display: flex;
	align-items: center;
	div {
		width: 14px;
		height: 14px;
		background: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px 0 0;
		&.activeway {
			border: 1px solid #2e77f6;
		}
		p {
			width: 8px;
			background-color: #2e78f6;
			height: 8px;
			border-radius: 50%;
		}
	}
}
.allway {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0 0 17px;
}
::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}
textarea:focus {
	border: 1px solid #4d91ff;
}
::v-deep {
	textarea {
		font-size: 14px;
		outline: none;
		padding: 13px 20px;
		width: 100%;
		height: 150px;
		background: #ffffff;
		border-radius: 4px;
		border: 1px solid #eeeeee;
		max-width: 100%;
		min-width: 100%;
		min-height: 100px !important;
	}
}

.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
</style>
